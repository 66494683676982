.content {
  width: 1200px;
  margin: auto;
  margin-bottom: 32px;

  h1 {
    margin-bottom: 32px;
    margin-bottom: 0px;
    color: var(--night900);
    margin-bottom: 24px;
  }
}

@media (max-width: 500px) {
  .content {
    width: auto;
    padding: 12px;

    .card {
      height: auto;

      p {
        margin: 0px;
      }
    }

    h1 {
      font-size: 28px;
    }
  }

  .placeholder {
    height: 160px !important;
  }
}
