.area {
  background-color: #f3f7f9;
}

.content {
  width: 1200px;
  margin: auto;
  margin-bottom: 32px;

  h1 {
    font-size: 48px;
    margin-bottom: 0px;
    color: var(--night900);
    margin-bottom: 24px;
  }
}

.card {
  background-color: white;
  // box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.056);
  border-radius: 12px;
  padding: 32px 18px;
  height: 330px;

  p {
    font-size: 18px;
    color: var(--night900);
    margin: 0px;
    margin-bottom: 24px;
    line-height: 28px;
  }

  strong {
    color: var(--primary);
  }
}

.placeholder {
  @extend .card;
  background-color: transparent;
  background-image: url(../../assets/empty.svg);
  height: 330px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 500px) {
  .content {
    width: auto;
    padding: 12px;

    .card {
      height: auto;

      p {
        margin: 0px;
      }
    }

    h1 {
      font-size: 28px;
    }
  }

  .placeholder {
    height: 160px !important;
  }
}
