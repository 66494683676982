.area {
  background-color: #f3f7f9;
}

.main {
  .navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 80px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    .headerButton {
      border: 2px solid white;
      width: fit-content;
      padding: 10px 24px;
      color: white;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 100px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: white;
        color: var(--primary);
      }
    }

    .logo {
      height: 40px !important;
      width: 120px !important;
      position: relative;

      .logoWhite {
        position: absolute;
        background-image: url(../../assets/logo_plain.svg);
        height: 40px !important;
        width: 120px !important;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.2s ease-in-out;
      }

      .logoColor {
        position: absolute;
        background-image: url(../../assets/logo_color.svg);
        height: 40px !important;
        width: 120px !important;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.2s ease-in-out;
        opacity: 0;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }

    .navContent {
      display: flex;
      width: 100vw;
      padding: 0px 48px;
      justify-content: space-between;
      align-items: center;

      .tabs {
        display: flex;
        align-items: center;
        column-gap: 24px;

        a {
          color: white;
          text-decoration: none;
          font-size: 16px;
          transition: all 0.2s ease-in-out;

          &:hover {
            cursor: pointer;
            color: var(--primary);
          }
        }
      }
    }

    @media (max-width: 500px) {
      height: 60px;

      .navContent {
        padding: 0px 12px;
      }

      .headerButton {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 500px) {
    overflow-x: hidden;
  }

  .navbarScrolled {
    @extend .navbar;

    background-color: rgba(255, 255, 255, 0.201);
    backdrop-filter: blur(10px);
  }

  .navbarDefault {
    @extend .navbarScrolled;

    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.04);

    .headerButton {
      border-color: var(--primary);
      color: var(--primary);

      &:hover {
        background-color: var(--primary);
        color: white;
      }
    }

    .logo {
      .logoColor {
        opacity: 1;
      }
    }

    .tabs {
      a {
        color: var(--night900) !important;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  position: relative;

  .topButton {
    border: 2px solid white;
    width: fit-content;
    padding: 10px 24px;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 100px;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      background-color: white;
      color: var(--primary);
    }
  }

  video {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }

  .info {
    width: 1200px;
    margin: auto;

    .label {
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      color: rgb(160, 160, 160);
      margin-bottom: 16px;
    }

    .slogan {
      color: white;
      font-size: 48px;
      font-weight: 600;
      text-align: left;
    }
  }

  @media (max-width: 500px) {
    .info {
      width: auto;

      .label {
        font-size: 16px;
      }

      .slogan {
        font-size: 24px;
      }
    }
  }
}

.whatWeDoWrap {
  position: relative;

  .whatWeDoBack {
    position: absolute;
    bottom: -40px;
    right: -40px;
    background-image: url(../../assets/back.svg);
    height: 500px;
    width: 820px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }
}

.whatDo {
  width: 1200px;
  margin: auto;
  padding: 70px 0px;

  h1 {
    font-size: 48px;
    margin-bottom: 0px;
    color: var(--night900);
  }

  p {
    font-size: 18px;
    color: #4a4a68;
    padding-bottom: 32px;
    width: 70%;
    line-height: 24px;
  }

  .card {
    background-color: white;
    height: 200px;
    // box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.056);
    border-radius: 12px;
    padding: 32px 18px;

    border: 3px solid white;

    .icon {
      font-size: 48px;
      margin-bottom: 18px;
    }

    h2 {
      padding: 0px;
      margin: 0px;
      margin-bottom: 18px;
      font-size: 18px;
      color: var(--night900);
    }

    p {
      padding: 0px;
      margin: 0px;
      color: #4a4a68;
      font-size: 16px;
      width: auto;
    }
  }

  .cardHoverable {
    @extend .card;

    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      border: 3px solid var(--night200);
      // box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
    }
  }

  @media (max-width: 500px) {
    width: auto;
    padding: 12px;

    .card {
      height: auto;
    }

    h1 {
      font-size: 28px;
    }
    p {
      width: auto;
      line-height: 24px;
    }
  }
}

.future {
  background-image: url(../../assets/bckground_cat.webp);
  background-position: center;

  .futureBlur {
    backdrop-filter: blur(20px);
  }

  .futureContent {
    width: 1200px;
    margin: auto;
    padding: 48px 0px;

    h1 {
      font-size: 48px;
      color: white;
    }

    p {
      font-size: 18px;
      color: white;
      padding-bottom: 32px;
      width: 70%;
      line-height: 28px;
    }
  }

  @media (max-width: 500px) {
    .futureContent {
      width: auto;
      padding: 24px 12px;

      h1 {
        font-size: 28px;
      }
      p {
        width: auto;
        line-height: 24px;
      }
    }
  }
}

.serviceWrap {
  position: relative;

  .serviceBack {
    position: absolute;
    // top: 460px;
    // left: -130px;
    bottom: 0px;
    right: 0px;
    background-image: url(../../assets/back.svg);
    height: 500px;
    width: 820px;
    background-repeat: no-repeat;
    background-size: contain;
    // transform: rotate(90deg);
  }

  strong {
    color: var(--primary);
  }
}

.services {
  @extend .whatDo;

  .card {
    height: 250px;
  }

  @media (max-width: 500px) {
    .card {
      height: auto;
    }
  }
}

.newsWrap {
  background-color: rgb(241, 249, 254);
}

.news {
  @extend .services;

  .article {
    background-color: white;
    height: 200px;
    // box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.026);
    border-radius: 12px;
    padding: 32px 18px;
    transition: all 0.2s ease-in-out;

    h2 {
      padding: 0px;
      margin: 0px;
      margin-bottom: 18px;
      font-size: 18px;
    }

    p {
      padding: 0px;
      margin: 0px;
      color: #4a4a68;
      font-size: 16px;
      width: auto;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.1);
    }
  }

  @media (max-width: 500px) {
    .article {
      height: auto;
    }
  }
}

.contacts {
  width: 1200px;
  margin: auto;
  padding-top: 48px;
  padding-bottom: 48px;

  h1 {
    font-size: 48px;
    margin-bottom: 0px;
    color: var(--night900);
  }

  p {
    font-size: 18px;
    color: #4a4a68;
    width: 70%;
  }

  .form {
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.043);
    border-radius: 24px;
    padding: 24px;
  }

  .contactLogo {
    background-image: url(../../assets/logo_color.svg);
    width: 180px;
    height: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 48px;
  }

  @media (max-width: 500px) {
    width: auto;
    padding: 24px 12px;

    .contactLogo {
      display: none;
    }

    h1 {
      font-size: 28px;
    }
    p {
      width: auto;
      line-height: 24px;
    }

    .form {
      margin-top: 32px;
      margin-bottom: 42px;
    }
  }
}

.contactForm {
  .form {
    border-radius: 12px;
  }

  form {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;

    label {
      font-size: 16px;
      font-weight: 500;
      color: var(--primary);
      margin-bottom: 6px;
      font-family: 'Poppins', sans-serif;
    }

    input {
      background-color: rgb(241, 249, 254);
      border: 1px solid rgb(201, 233, 250);
      min-height: 30px;
      padding: 6px;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      border-radius: 6px;
    }

    textarea {
      background-color: rgb(241, 249, 254);
      border-color: rgb(201, 233, 250);
      min-height: 70px;
      padding: 6px;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      border-radius: 6px;
      resize: none;
    }

    select {
      background-color: rgb(241, 249, 254);
      border: 1px solid rgb(201, 233, 250);
      min-height: 40px;
      padding: 6px;
      border-radius: 6px;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
    }

    input[type='submit'] {
      border: 2px solid var(--primary);
      background-color: white;
      transition: all 0.2s ease-in-out;
      color: var(--primary) !important;

      &:hover {
        cursor: pointer;
        background-color: var(--primary);
        color: white !important;
      }
    }
  }
}

.footer {
  background-image: url(../../assets/pattern.svg);

  .footerColor {
    background-color: rgba(0, 0, 0, 0.494);
  }

  .footerContent {
    width: 1200px;
    margin: auto;
    padding: 48px 0px;

    .cat {
      background-image: url(../../assets/just_cat.svg);
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    h6 {
      color: white;
      padding: 0px;
      margin: 0px;
      font-size: 16px;
      font-weight: 600;
    }

    a {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }
  }
}

@media (max-width: 500px) {
  .footer {
    .footerContent {
      width: auto;
      padding: 32px 24px;
      text-align: center;

      h6 {
        font-size: 18px;
      }

      a {
        font-size: 16px;
      }
    }
  }
}
