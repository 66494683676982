.navbar {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background-color: #003d69f7;
  position: sticky;
  top: 0px;
  z-index: 100;
  backdrop-filter: blur(10px);

  .navContent {
    display: flex;
    width: 100vw;
    padding: 0px 48px;
    justify-content: space-between;
    align-items: center;

    .logo {
      background-image: url(../../assets/logo_plain.svg);
      height: 40px;
      width: 120px;
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }

    .tabs {
      display: flex;
      align-items: center;
      column-gap: 24px;

      a {
        color: white;
        text-decoration: none;
        font-size: 16px;
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          filter: brightness(0.8);
        }
      }
    }
  }
}

.navMobile {
  height: 60px;
  transition: all 0.2s ease-in-out;
  background-color: var(--primary);
  overflow-y: hidden;
  position: sticky;
  top: 0px;
  z-index: 100;
  backdrop-filter: blur(10px);

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
  }

  .mobileMenuOptions {
    height: 300px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    background-color: var(--primary);
    padding-top: 12px;

    a {
      font-size: 20px;
      color: white;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .logo {
    background-image: url(../../assets/logo_plain.svg);
    height: 30px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
  }

  .menu {
    font-size: 24px;
    color: white;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
  }

  .menuActive {
    @extend .menu;

    transform: rotate(90deg);
    font-size: 28px;
  }
}

.button {
  border: 2px solid white;
  width: fit-content;
  padding: 10px 24px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  background-color: var(--primary);

  &:hover {
    cursor: pointer;
    background-color: white;
    color: var(--primary) !important;
  }
}
