@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0px;
  background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar {
  display: none !important;
}

:root {
  --background: #f8fbfd;
  --blue: #4094da;
  --border: #eaedf5;
  --ocean: #3396aa;
  --mentol: #d4f2fb;
  --yellow: #ffc107;
  --sun: #f1651b;
  --orange: #b94609;
  --fury: #dd2c00;
  --green: #41c036;
  --night100: #f2f3f7;
  --night200: #eaedf5;
  --night300: #c0c3ce;
  --night500: #9fa1ae;
  --night700: #6c7283;
  --night900: #2c2f3c;
  --primary: #003e69;
  --primary10: rgba(81, 51, 170, 0.028);
  --primary20: rgba(81, 51, 170, 0.048);
  --primary30: rgba(81, 51, 170, 0.068);
  --primary40: rgba(81, 51, 170, 0.12);
  --primary80: rgba(81, 51, 170, 0.52);
}

.b24-form-sign {
  display: none !important;
}

.b24-form-wrapper.b24-form-border-bottom {
  border-bottom: none !important;
}

.b24-form-header-description {
  display: none !important;
}

.b24-form-header {
  display: none;
}

.b24-form-content {
  padding: 24px !important;
}

.b24-form-btn {
  background-color: var(--primary) !important;
}

.b24-form-control {
  background-color: white !important;
}
