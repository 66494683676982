.footer {
  // background-color: var(--primary);
  background-image: url(../../assets/pattern.svg);

  .footerColor {
    background-color: rgba(0, 0, 0, 0.494);
  }

  .footerContent {
    width: 1200px;
    margin: auto;
    padding: 48px 0px;

    .cat {
      background-image: url(../../assets/just_cat.svg);
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    h6 {
      color: white;
      padding: 0px;
      margin: 0px;
      font-size: 16px;
      font-weight: 600;
    }

    a {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }
  }
}

@media (max-width: 500px) {
  .footer {
    .footerContent {
      width: auto;
      padding: 32px 24px;
      text-align: center;

      h6 {
        font-size: 18px;
      }

      a {
        font-size: 16px;
      }
    }
  }
}
